
 // @ is an alias to /src
import Navigation from '@/components/partials/Navigation.vue';
import Aside from '@/components/partials/Aside.vue';
import Footer from '@/components/partials/Footer.vue';
import { defineComponent, ref, reactive, computed } from 'vue';
import { useStore } from 'vuex';
export default defineComponent({
  name: "Score",
  components: {
    Navigation,
    Aside,
    Footer
  },
  setup() {
    const store = useStore();
    const years = reactive<number[]>([]);
    const year = ref("");
    const term = ref("");
    const links = reactive([
      {
        name: "Preview Score",
        url: "exam/score",
        target: "preview"
      },
      {
        name: "Publish Score",
        url: "exam/score",
        target: "publish"
      },
    ]);
    const fullYear = ref(new Date().getFullYear());
    for ( var i: number = fullYear.value; i >= fullYear.value - 6; i-- ) {
      years.push(i);
    }
    const exam = reactive({
      id: "1",
      name: "Test",
      limit: "30",
      date: "2020-08-17",
      time: "10:15",
      duration: "60",
      levels: [],
      status: false,
      scores: [
        {id: 1, reg_id: "0020186", score: 78, submitted: 1619634314063},
        {id: 2, reg_id: "0020166", score: 88, submitted: 1619634314063},
        {id: 3, reg_id: "0020187", score: 69, submitted: 1619634314063},
        {id: 4, reg_id: "0020131", score: 78, submitted: 1619634314063},
      ]
    });
    const name = ref("Exam");
    const nav = computed(() => store.getters.nav);

    return { name, nav, links, years, year, term, exam };
  },
});
